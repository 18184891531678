import { parse } from "wellknown"

const config = useRuntimeConfig()
const URL = config.public.apiBase + "/api/infra"

export const useTollStations = () => useState<any[]>("tollStations", () => [])
export const useTollGates = () => useState<any[]>("tollGates", () => [])
export const useTollLanes = () => useState<any[]>("tollLanes", () => [])
export const useTollZones = () => useState<any[]>("tollZones", () => [])
export const useBusBarriers = () => useState<any[]>("busBarriers", () => [])
export const useBusLanes = () => useState<any[]>("busLanes", () => [])
export const useBusStations = () => useState<any[]>("busStations", () => [])
export const useRoadLanes = () => useState<any[]>("roadLanes", () => [])
export const useRoadRoads = () => useState<any[]>("roadRoads", () => [])
export const useRoadZones = () => useState<any[]>("roadZones", () => [])
export const useCameras = () => useState<any[]>("cameras", () => [])
export const useUbrs = () => useState<any[]>("ubrs", () => [])

const tollStations = useTollStations()

export async function getTollStations () {
  const { error } = await useFetch(URL + "/tolls/stations", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useTollStations().value = response._data.stations
    }
  })
}

export function getTollStationById (id: string) {
  let stationn = null
  tollStations.value.map((station) => {
    if (station.id === id) {
      stationn = station
    }
    return null
  })
  return stationn
}

export async function getTollGates () {
  const { error } = await useFetch(URL + "/tolls/gates", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useTollGates().value = response._data.gates
    }
  })
}

export function tollGatesByStation (stationId: string) {
  const tollGatesFiltered: any[] = []
  useTollGates().value.map((tollgate) => {
    if (tollgate.station_id === stationId) {
      tollGatesFiltered.push(tollgate)
    }
    return null
  })
  return tollGatesFiltered
}

export async function getTollLanes () {
  const { error } = await useFetch(URL + "/tolls/lanes", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useTollLanes().value = response._data.lanes
    }
  })
}

export function tollLanesByStation (stationId: string) {
  const tollLanesFiltered: any[] = []
  useTollLanes().value.map((tollLanes) => {
    if (tollLanes.station_id === stationId) {
      tollLanesFiltered.push(tollLanes)
    }
    return null
  })
  return tollLanesFiltered
}

export async function getTollZones () {
  const { error } = await useFetch(URL + "/tolls/zones", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useTollZones().value = response._data.zones
    }
  })
}
export async function getBusStations () {
  const { error } = await useFetch(URL + "/bus/stations", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useBusStations().value = response._data.stations
    }
  })
}

export async function getBusBarriers () {
  const { error } = await useFetch(URL + "/bus/barriers", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useBusBarriers().value = response._data.barriers
    }
  })
}

export async function getBusLanes () {
  const { error } = await useFetch(URL + "/bus/lanes", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useBusLanes().value = response._data.lanes
    }
  })
}

export async function getRoadRoads () {
  const { error } = await useFetch(URL + "/road/roads?epsg=4326", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      const roads: any[] = []
      response._data.roads.foreach((road: { linestring: string; polygon: string }) => {
        road.linestring = parse(road.linestring).coordinates
        road.polygon = parse(road.polygon).coordinates
        roads.push(road)
      })
      useRoadRoads().value = roads
    }
  })
}

export async function getRoadLanes () {
  const { error } = await useFetch(URL + "/road/lanes?epsg=4326", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      const responseLanes = response._data.lanes
      const lanes = []
      for (let i = 0; i < responseLanes.length; i++) {
        responseLanes[i].left_line = parse(responseLanes[i].left_line).coordinates
        responseLanes[i].center_line = parse(responseLanes[i].center_line).coordinates
        responseLanes[i].right_line = parse(responseLanes[i].right_line).coordinates
        responseLanes[i].polygon = parse(responseLanes[i].polygon).coordinates
        lanes.push(responseLanes[i])
      }
      useRoadLanes().value = lanes
    }
  })
}

export async function getRoadZones () {
  const { error } = await useFetch(URL + "/road/zones?epsg=4326", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      const zones: any[] = []
      response._data.zones.foreach((zone: { polygon: string }) => {
        zone.polygon = parse(zone.polygon).coordinates
        zones.push(zone)
      })
      useRoadZones().value = zones
    }
  })
}

export async function getCameras () {
  const { error } = await useFetch(URL + "/cameras", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useCameras().value = response._data.cameras
    }
  })
}

export function getCameraById (id: string) {
  let cameraa = null
  useCameras().value.map((camera) => {
    if (camera.id.toString() === id) {
      cameraa = camera
    }
    return null
  })
  return cameraa
}

export async function getCamerasByUbrId () {

}

export async function getUbrs () {
  const { error } = await useFetch(URL + "/ubrs", {
    onResponseError () { console.error(error) },
    onResponse ({ response }) {
      useUbrs().value = response._data.ubrs
    }
  })
}
export function getUbrById (id: string) {
  let ubrr = null
  useUbrs().value.map((ubr) => {
    if (ubr.id.toString() === id) {
      ubrr = ubr
    }
    return null
  })
  return ubrr
}
